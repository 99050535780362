"use client";

import Lottie from "lottie-react";

import loader from '../../public/images/paw-loader.json';
import {Center} from "@mantine/core";

export default function PageTransitionAnimation() {
    return (
        <div style={{display: 'flex', flexDirection: 'column', gap: 'var(--mantine-spacing-xl)', flex: 1, minHeight: '100%'}}>
            <Center>
                <Lottie animationData={loader} style={{ height: 300 }} />
            </Center>
        </div>
    );
}
